.filter_container {
  box-sizing: border-box;
  width: 100%;
  background: #444;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;

  .item {
    background: #aaa;
    padding: 0.5rem 1rem;
    text-align: center;
    border-radius: 50vw;
    border: 2px solid #ddd;
    background: inherit;
    color: #ddd;

    &.filter-celebrations {
      background: #7d5e00;
    }
    &.filter-types {
      background: #590505;
    }
    &.filter-sources {
      background: #004560;
    }

    .remove {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 0.2;
      position: relative;
      top: 0.2rem;
      left: 0.2rem;
      cursor: pointer;

    }
  }
}
