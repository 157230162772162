@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Symbols&display=swap');

.showItem {
  display: grid;
  gap: 0;

  a {
    text-decoration: none;
  }

  h1 {
    background: #444;
    margin: 0;
    padding: 0.75rem;
    color: #f8f8f8;
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    a {
      background: transparent;
      border: none;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      padding-right: 0.5rem;
      line-height: 0;
      user-select: none;
      font-family: 'Noto Sans Symbols', sans-serif;
      position: relative;
      top: -3px;
    }
  }

  .content {
    display: grid;
    min-height: 12rem;
    grid-template-columns: 1fr min(32rem, 30%);
    padding: 1rem;
    gap: 1.5rem;
    background: rgb(238, 238, 238, 80%);

    .body {
      max-width: 35rem;
      background: #f8f8f8;
      padding: 0.5rem;
      box-shadow: 0.5rem 0.5rem 0.5rem #44444444;

      p:first-child {
        margin-top: 0;
      }
    }

    @media (max-width: 40rem) {
      grid-template-columns: 1fr;
    }

    .info {
      button {
        font-size: inherit;
        padding: 0.5rem 3rem;
        text-align: center;
        border-radius: 50vw;
        margin: 0.5rem 0;
      }

      div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 0.25rem;
        row-gap: 0;

        .tag {
          background: #aaa;
          padding: 0.5rem 1rem;
          text-align: center;
          border-radius: 50vw;
          border: 2px solid #ddd;
          background: inherit;
          color: #ddd;

          &.celebrations {
            background: #7d5e00;
          }
          &.types {
            background: #590505;
          }
          &.sources {
            background: #004560;
          }
        }
      }
    }
  }
}
