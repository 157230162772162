.result_container {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-auto-rows: 9rem;
  gap: 1rem;
  padding: 0.75rem;
  background: rgb(238, 238, 238, 80%);
  color: #444;
}
