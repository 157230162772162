.inputContainer {
  flex: 1;
  min-width: 20rem;
  position: relative;
}

input {
  padding: 0.5rem 0.2rem;
  margin: 0.1rem 0.3rem;
  background: #444;
  color: #ddd;
  font-size: 0.9rem;
  border: none;
  border-bottom: 1px solid #eee;
  font-family: inherit;
  display: inline-block;
  width: 95%;

  &:focus {
    outline: none;
  }
}
