@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");

html {
  height: 100%;
  overflow-y: scroll;
}
body {
  font-size: 16px;
  font-family: Montserrat;
  background: repeating-linear-gradient(-20deg, transparent 0 5px, hsl(270, 80%, 94%) 5px 7px, transparent 7px 12px, hsl(270, 80%, 94%) 12px 16px);
}
.main_container {
  width: min(75rem, 95%);
  margin-inline: auto;
  font-size: 0.9rem;
  background: transparent;
}
