.filterOptions {
  position: absolute;
  z-index: 1;
  width: max(20rem, 85%);
  background: #444;
  left: 0;

  .filterOption {
    padding: 0.5rem;
    color: #ddd;
    cursor: pointer;

    &[data-selected='true'] {
      background: #222;
    }
  }
}
