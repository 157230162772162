.result_container {
  a {
    background: #f8f8f8;
    padding: 0.5rem;
    box-shadow: 0.5rem 0.5rem 0.5rem #44444444;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: inherit;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, transparent 80%, #f8f8f8 95%);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .header {
    font-weight: bold;
    display: block;
  }
  .content {
    font-size: 0.75rem;
    line-height: 1.3;
    display: block;
  }
}
